.backdrop {
  position: fixed;
  z-index: 3000;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-out;
}

.backdrop.active {
  opacity: 1;
  pointer-events: all;
}

.icdr-modal {
  z-index: 3001;
  position: fixed;
  top: 0%;
  left: 50%;
  width: 25rem;
  max-width: 95%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-out;
  text-align: center;
  min-width: 20rem;
}
.icdr-modal.active {
  top: 50%;
  opacity: 1;
  pointer-events: all;
}

.icdr-modal .close {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  font-size: 1.5rem;
}
